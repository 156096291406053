import { ref } from 'vue'
import { useToast } from '@/composables/useToast'
import type { ToastParams } from '@/types/toast'
import { IS_PRODUCTION } from '@/constants/index.js'

const { addToast } = useToast()

const error = ref('')

export function useErrorReporter (reporterService: any) {
	const reportError = (
		err: string,
		showError = false, // optional flag to show error toast
		toast: ToastParams = { // optional custom toast params
			title: 'Oops!', // default error title (required *if custom toast params are passed)
			message: 'Something went wrong. Please try again later.' // default error message (optional)
		}) => {
		const { title, message } = toast
		error.value = err
		if (showError) {
			addToast({
				title,
				message,
				options: { duration: 7000 }
			})
		}
		reporterService(err)
		if (!IS_PRODUCTION) {
			// eslint-disable-next-line no-console
			console.log(err)
		}
	}

	return {
		error,
		reportError
	}
}
